import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Room from '../views/Room.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'not_found',
    component: Home
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/room/:token',
    name: 'room',
    component: Room,
    beforeEnter: (to, from, next) => {
      if (!to.params.token.match(/^[a-z0-9]{10}$/)) {
        next('home')
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
