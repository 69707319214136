<template>
    <modal
      :name="name"
      width="400"
      height="auto"
      :clickToClose="false"
    >
      <div class="mb p-5">
        <slot></slot>
        <button
          v-if="showOkButton"
          class="w-24 mt-3 ml-1 mr-1 btn btn-blue"
          @click="emitOk"
        >
          Ok
        </button>
        <button
          v-if="showCancelButton"
          class="w-24 mt-3 ml-1 mr-1 btn btn-blue"
          @click="emitCancel"
        >
          Cancel
        </button>
      </div>
    </modal>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    showOkButton: {
      type: Boolean,
      required: false,
      default: () => true
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  methods: {
    emitOk() {
      this.$emit('ok')
      this.$modal.hide(this.name)
    },
    emitCancel() {
      this.$modal.hide(this.name)
    }
  }
}
</script>