export default function (store) {
  const processMessage = (event) => {
    const message = JSON.parse(event.data);
    if (!message) {
      return;
    }
    switch (message.type) {
      case 'room':
        store.commit('setRoom', message.data.room)
        break;
      case 'roomClosed':
        store.commit('setRoomClosed')
        break;
      case 'users':
        store.commit('setRoomUsers', message.data.users)
        break;
      case 'roundUpdate': {
        store.commit('updateRound', message.data.round)
        break;
      }
      case 'roundDeleted': {
        store.commit('deleteRound', message.data.roundId)
        break;
      }
      case 'roundCardsUpdate': {
        // Having an event for round cards update allows to more easily keep other data
        // of the round in case of concurrent events (ex name being typed that disappears because a card is played)
        store.commit('updateSelectedCards', {
          roundId: message.data.roundId,
          cards: message.data.cards
        })
        break;
      }
      case 'newRound':
        store.commit('newRound', message.data.round)
        break;
      case 'cardsType':
        store.commit('setCardsType', message.data.cardsType)
        break;
      case 'allowToChangeCardAfterRoundEnd':
        store.commit('updateAllowToChangeCardAfterRoundEnd', message.data.checked)
        break;
    }
  }

  store.subscribe(mutation => {
    if (mutation.type === 'createWebsocket') {
      store.state.socket.onmessage = (event) => {
        processMessage(event)
      }
    }
  })
}