import Vue from 'vue'
import Vuex from 'vuex'
import CryptoHelpers from '../helpers/Crypto'
import WebsocketPlugin from './plugins/websocket'
import localforage from 'localforage'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [WebsocketPlugin],
  state: {
    socket: null,
    room: {
      users: [],
      rounds: [],
      cardsType: '',
      closed: false,
      allowToChangeCardAfterRoundEnd: false
    },
    user: { // Only those 2 fields. A more complete version of is available with currentUser().
      username: null,
      browserId: null
    }
  },
  getters: {
    currentUser: (state) =>  {
      return state.room.users.find(u => u.browserId === state.user.browserId) ?? {}
    },
    roomUsers: (state) => {
      return state.room.users || []
    },
    selectedCards: (state) => {
      return state.room.rounds[0]?.selectedCards || []
    },
    roundIsClosed: (state) => {
      return state.room.rounds[0]?.closed || false
    },
    roundName: (state) => {
      return state.room.rounds[0]?.name
    },
    roundId: (state) => {
      return state.room.rounds[0]?.id
    },
    allowToChangeCardAfterRoundEnd: (state) => {
      return state.room.allowToChangeCardAfterRoundEnd
    }
  },
  mutations: {
    createWebsocket (state, path) {
      const host = process.env.VUE_APP_WS_HOST ??  window.location.hostname;
      state.socket = new WebSocket(`${process.env.VUE_APP_WS_PROTOCOL}://${host}:${process.env.VUE_APP_WS_PORT}/ws/${path}`)
    },
    setUserProperties (state, {username, browserId}) {
      state.user = {
        username: username ?? state.user.username,
        browserId: browserId ?? state.user.browserId
      }
      localforage.setItem('user', state.user)
    },
    setRoom (state, room) {
      state.room = room;
    },
    setRoomClosed (state) {
      state.room.closed = true;
    },
    setRoomUsers (state, users) {
      state.room.users = users;
    },
    updateRound (state, round) {
      const roundIndex = state.room.rounds.findIndex(r => r.id === round.id)
      if (roundIndex !== -1) {
        Vue.set(state.room.rounds, roundIndex, round)
      }
    },
    deleteRound (state, roundId) {
      const roundIndex = state.room.rounds.findIndex(r => r.id === roundId)
      if (roundIndex !== -1) {
        state.room.rounds.splice(roundIndex, 1);
        console.log(state.room.rounds);
      }
    },
    newRound (state, round) {
      state.room.rounds.unshift(round)
    },
    setRoundName (state, {roundId, name}) {
      const round = state.room.rounds.find(round => round.id === roundId)
      round.name = name
    },
    setCardsType (state, cardsType) {
      state.room.cardsType = cardsType
    },
    updateSelectedCards(state, {roundId, cards}) {
      const roundIndex = state.room.rounds.findIndex(r => r.id === roundId)
      if (roundIndex !== -1) {
        const round = state.room.rounds[roundIndex]
        round.selectedCards = cards
        Vue.set(state.room.rounds, roundIndex, round)
      }
    },
    updateAllowToChangeCardAfterRoundEnd (state, checked) {
      state.room.allowToChangeCardAfterRoundEnd = checked
    }
  },
  actions: {
    sendMessage ({state}, {action, data}) {
      state.socket.send(JSON.stringify({
        action: action,
        msgId: CryptoHelpers.randomChars(16),
        user: state.user,
        data: data
      }))
    }
  },
  modules: {
  }
})
