<template>
  <div class="">
    <header class="p-2 flex flex-row justify-between mb-10 flex-wrap items-center bg-primary">
      <div class="w-48 flex-shrink-0">
        <img
          class="min-w-full"
          src="/images/pokerplan_green.svg"
        >
      </div>
      <a
        class="text-white block sm:hidden"
        href="/"
      >
        <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
        Quit
      </a>
      <div class="text-sm border rounded text-white w-full sm:w-auto sm:pl-4">
        <span class="mr-2">{{ windowLocation }}</span>
        <font-awesome-icon
          v-if="linkCopied"
          icon="check"
          class="absolute mt-2"
        />
        <button
          class="btn btn-sm ml-1"
          v-clipboard:copy="windowLocation"
          v-clipboard:success="onCopy"
        >
          Copy
        </button>
      </div>
      <a
        class="text-white hidden sm:block"
        href="/"
      >
        <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
        Quit
      </a>
    </header>

    <main class="p-5">
      <div class="flex flex-row justify-between items-baseline">
        <h1 class="text-left text-2xl font-bold mt-5 mb-2">Deck</h1>
        <template v-if="currentUser.role === 'dealer'">
          <button
            v-if="!roundIsClosed"
            class="mt-5 mt-3 btn btn-blue"
            @click="endRound"
          >
            End round
          </button>
          <button
            v-else
            class="mt-5 mt-3 btn btn-green"
            @click="newRound"
          >
            New round
          </button>
        </template>
      </div>

      <UserList
          :users="roomUsers"
          :cards="selectedCards"
          :currentUser="currentUser"
          @userOption="setUserOption"
        ></UserList>

      <Deck
        :cards="selectedCards"
        :roundIsClosed="roundIsClosed"
        :user="currentUser"
        :roundId="roundId"
        :roundName="roundName"
        @roundName="setRoundName"
      />

      <div
        v-if="currentUser.role === 'dealer'"
        class="mt-2 flex"
      >
        <label class="inline-flex items-center mt-3">
          <input
            type="checkbox"
            class="focus:shadow-none rounded form-checkbox h-5 w-5 border-gray-500 border-2 text-blue-600 cursor-pointer"
            :checked="allowToChangeCardAfterRoundEnd"
            @change="setAllowToChangeCardAfterRoundEnd"
          >
            <span class="ml-2 text-gray-700">
              Allow to change card after the round ends
            </span>
        </label>
      </div>

      <div class="flex flex-row justify-between mt-10">
        <h1 class="text-left text-2xl font-bold mt-5 mb-2">Cards</h1>
        <Dropdown
          class="mt-5"
          :disabled="currentUser.role !== 'dealer' || roundIsClosed"
          :options="[{name: 'Days', value: 'days'}, {name: 'Hours', value: 'hours'},{name: 'Linear', value: 'linear'},{name: 'Fibonacci', value: 'fibonacci'}]"
          :value="room.cardsType"
          :buttonClass="{'btn btn-blue': currentUser.role === 'dealer'}"
          @select="setCardsType"
        />
      </div>
      <CardList
        @selectCard="selectCard"
        :type="room.cardsType"
        class="bg-gray-100"
        :class="roundIsClosed && !allowToChangeCardAfterRoundEnd ? 'disabled-component' : ''"
      />

      <h1 class="text-left text-2xl font-bold mt-10 mb-2">History</h1>
      <RoundsHistory
        :rounds="room.rounds"
        :user="currentUser"
        @roundName="setRoundName"
        @deleteRound="deleteRound"
      />
    </main>

    <Alert
      name="roomClosed"
      @ok="quitRoom"
    >
        <h3 class="font-bold text-xl">Room closed</h3>
        <p class="mt-5">This room is has been closed because it has been inactive for more than a week.</p>
    </Alert>

    <Alert
      name="connectionIssue"
      :showOkButton="false"
    >
        <font-awesome-icon
          icon="exclamation-circle"
          class="text-3xl"
        />
        <h3 class="font-bold text-xl">Connection error</h3>
        <p class="mt-5">There is an issue connecting to the server. Please reload the page.</p>
    </Alert>

    <Alert
      name="deleteRoundConfirmation"
      :showOkButton="true"
      :showCancelButton="true"
      @ok="reallyDeleteRound"
    >
        <font-awesome-icon
          icon="trash-alt"
          class="text-3xl"
        />
        <h3 class="font-bold text-xl">Really delete this round?</h3>
        <p>(no undo)</p>
    </Alert>

    <FormModal
      @submit="registerInRoom"
      @opened="$refs.username.focus()"
    >
      <label class="block text-gray-900 font-bold mb-5" for="username">
        Username
      </label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        ref="username"
        maxlength="15"
        id="username"
        :value="user.username"
        placeholder="Username"
        @keyup.enter="registerInRoom"
      >
      <template v-slot:submitButton>Enter room</template>
    </FormModal>
  </div>
</template>
<script>
import CryptoHelpers from '../helpers/Crypto'
import localforage from 'localforage'
import Dropdown from '../components/Dropdown'
import Alert from '../components/dialogs/Alert'
import FormModal from '../components/dialogs/FormModal'
import UserList from '../components/UserList'
import CardList from '../components/CardList'
import Deck from '../components/Deck'
import RoundsHistory from '../components/RoundsHistory'
import { mapState, mapGetters } from 'vuex'

export default {
  components: { UserList, CardList, Deck, Dropdown, RoundsHistory, Alert, FormModal },
	data() {
		return {
      token: this.$route.params.token,
      linkCopied: false
		}
  },
  watch: {
    room: {
      deep: true,
      handler: function(room) {
        if (room.closed) {
          this.$modal.show('roomClosed');
        }
    }}
  },
  computed: {
    ...mapState([
      'room',
      'user',
      'socket'
    ]),
    ...mapGetters([
      'currentUser',
      'roomUsers',
      'selectedCards',
      'roundIsClosed',
      'roundName',
      'roundId',
      'allowToChangeCardAfterRoundEnd'
    ]),
    windowLocation() {
      return window.location
    }
  },
  created () {
    this.copyTimeout = null
    this.roundNameTimeout = null
    this.roundToDelete = null
  },
  mounted () {
    this.$store.commit('createWebsocket', this.token)

    this.socket.onopen = () => {
      localforage.getItem('user').then(user => {
        if (user) {
          this.$store.commit('setUserProperties', {
            username: user.username,
            browserId: user.browserId
          });
          this.$store.dispatch('sendMessage', {
            action: 'register'
          })
        } else {
          this.$modal.show('register')
          this.$store.commit('setUserProperties', {
            browserId: CryptoHelpers.randomChars(16)
          });
          this.saveUser()
        }
      })
    };

    this.socket.onclose = () => {
      this.$modal.show('connectionIssue')
    }
  },
  methods: {
    registerInRoom() {
      const username = this.$refs.username.value

      if (username.length === 0) {
        return;
      }

      this.$store.commit('setUserProperties', {
        username: username
      })
      this.$store.dispatch('sendMessage', {
        action: 'register',
      })

      this.$modal.hide('register')
    },
    selectCard (card) {
      this.$store.dispatch('sendMessage', {
        action: 'selectCard',
        data: card.value
      })
    },
    setCardsType(type) {
      this.$store.dispatch('sendMessage', {
        action: 'cardsType',
        data: type
      })
    },
    endRound() {
      this.$store.dispatch('sendMessage', {
        action: 'endCurrentRound'
      })
    },
    newRound() {
      this.$store.dispatch('sendMessage', {
        action: 'newRound'
      })
    },
    onCopy() {
      this.linkCopied = true
      clearTimeout(this.copyTimeout)
      this.copyTimeout = setTimeout(() => {
        this.linkCopied = false
      }, 2000)
    },
    setUserOption(option) {
      switch (option.name) {
        case 'role':
          this.$store.dispatch('sendMessage', {
            action: 'setUserRole',
            data: {
              browserId: option.user.browserId,
              role: option.value
            }
          })
          break;
        case 'username':
          this.$modal.show('register')
          break;
      }
    },
    setRoundName({roundId, name}) {
      this.$store.commit('setRoundName', {
        roundId: roundId,
        name: name
      })
      clearTimeout(this.roundNameTimeout)
      this.roundNameTimeout = setTimeout(() => {
        this.$store.dispatch('sendMessage', {
          action: 'roundName',
          data: {
            roundId: roundId,
            name: name
          }
        })
      }, 500)
    },
    deleteRound({roundId}) {
      this.roundToDelete = roundId
      this.$modal.show('deleteRoundConfirmation');
    },
    reallyDeleteRound() {
      this.$store.dispatch('sendMessage', {
        action: 'deleteRound',
        data: {
          roundId: this.roundToDelete
        }
      })
    },
    setAllowToChangeCardAfterRoundEnd(event) {
      this.$store.dispatch('sendMessage', {
        action: 'setAllowToChangeCardAfterRoundEnd',
        data: {
          checked: event.target.checked
        }
      })
    },
    quitRoom () {
      this.$router.push('/');
    }
  }
}
</script>