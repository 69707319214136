<template>
  <div
    class="users-list flex flex-wrap mb-2"
  >
    <User
      v-for="user in connectedUsers"
      :key="user.browserId"
      :user="user"
      :isCurrentUser="user.browserId === currentUser.browserId"
      :currentUserIsDealer="currentUser.role === 'dealer'"
      :hasPlayed="hasPlayed(user)"
      @setOption="emitUserOption"
    />
  </div>
</template>
<script>
import User from './User'

export default {
  components: {User},
  props: {
    users: {
      type: Array,
      required: true
    },
    cards: {
      type: Array,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    connectedUsers() {
      return this.users.filter(user => user.state === 'connected')
    }
  },
  methods: {
    emitUserOption(option) {
      this.$emit('userOption', option)
    },
    hasPlayed(user) {
      return this.cards.findIndex(c => c.user.browserId === user.browserId) !== -1
    }
  }
}
</script>
