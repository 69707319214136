<template>
  <div
    class="cards-list flex box-content flex-wrap p-2 rounded"
  >
    <Card
      class="w-16 h-24 mx-2 my-2 text-xl"
      v-for="card in cards"
      :key="card.value"
      :content="card"
      @clicked="selectCard"
    />
  </div>
</template>
<script>
import Card from './Card'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: { Card },
  computed: {
    cards () {
      switch (this.type) {
        case 'days':
          return [0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20].map(value => {
            return {value: value}
          });
        case 'hours':
          return [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 16, 24, 32, 48, 64].map(value => {
            return {value: value}
          });
        case 'fibonacci':
          return [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233].map(value => {
            return {value: value}
          });
        default:
          return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(value => {
            return {value: value}
          });
      }
    }
  },
  methods: {
    selectCard (card) {
      this.$emit('selectCard', card)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>