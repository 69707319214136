<template>
  <div>
    <div
      v-for="(round, index) in pastRounds"
      :key="index"
    >
      <div class="flex flex-row">
        <input
          type="text"
          class="bg-gray-100 rounded p-1 mb-0 text-gray-800 text-base font-semibold outline-none w-full"
          placeholder="Feature name"
          :disabled="user.role !== 'dealer'"
          :value="round.name"
          @keyup="(event) => setRoundName(round.id, event.target.value)"
        >
        <div class="text-xs inline-flex items-center font-bold uppercase px-3 py-1 text-gray-600 border border-gray-200 ">
          {{ round.cardsType }}
        </div>
        <div
          v-if="user.role === 'dealer'"
          class="bg-gray-100 inline-flex px-3 pt-2 "
        >
          <font-awesome-icon
            icon="trash-alt"
            @click="deleteRound(round.id)"
            class="text-base cursor-pointer"
          />
        </div>
      </div>

      <div class="deck flex box-content bg-white p-2 overflow-x-auto">
        <Card
          v-for="(card, index) in round.selectedCards"
          :key="index"
          :showUser="true"
          :content="card"
          size="small"
        />

        <div
          class=" mx-2 flex-shrink-0 border-gray-500 p-1 h-20 overflow-hidden flex flex-col items-center justify-center text-xl font-bold cursor-pointer"
        >
          <div class="text-gray-600 text-xs">Average</div>
          <div class="text-base">{{ cardsAverage(round.selectedCards) }}</div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Card from './Card'
export default {
  components: {Card},
  props: {
    rounds: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    pastRounds() {
      return this.rounds.slice(1).filter(r => r.closed === true)
    }
  },
  methods: {
    setRoundName(roundId, name) {
      this.$emit('roundName', {
        roundId: roundId,
        name: name
      })
    },
    deleteRound(roundId) {
      this.$emit('deleteRound', {
        roundId: roundId
      })
    },
    cardsAverage(cards) {
      const cardsWithFloatValues = cards.filter(c => Number.isFinite(c.value))
      const total = cardsWithFloatValues.reduce((acc, card) => acc + parseFloat(card.value), 0)
      return Math.round(total / cardsWithFloatValues.length * 100) / 100
    }
  }
}
</script>
