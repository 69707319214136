<template>
  <div
    class="flex-shrink-0"
    :class="{'mx-2 my-2 text-xl w-16': size === 'big', 'mx-1 my-1 text-base w-12': size === 'small'}"
  >
    <transition name="flip">
      <div
        @click="handleClick"
        v-if="flipPosition === -1"
        class="border-gray-500 bg-white border p-1 rounded overflow-hidden flex flex-col items-center justify-center font-bold cursor-pointer"
        :class="{'h-24': size === 'big', 'h-16': size === 'small'}"
      >
        <div
          class="bg-teal-300 w-full h-full rounded"
        >
        </div>
      </div>
      <div
        @click="handleClick"
        v-else-if="flipPosition === 1"
        class="border-gray-500 bg-white border p-1 rounded overflow-hidden flex flex-col items-center justify-center font-bold cursor-pointer"
        :class="{'h-24': size === 'big', 'h-16': size === 'small'}"
      >
        <div>
          {{ content.value }}
        </div>
      </div>
    </transition>
    <div
      v-if="showUser"
      class="text-xs text-center break-words"
    >
      {{ content.user.username }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },
    flipped: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showUser: {
      type: Boolean,
      required: false,
      default: () => false
    },
    size: {
      type: String,
      required: false,
      default: 'big',
      validator: (value) => ['big', 'small'].indexOf(value) !== -1
    },
    position: {
      type: Number,
      required: false,
      default: () => 0
    },
    numberOfCards: {
      type: Number,
      required: false,
      default: () => 0
    }
  },
  data() {
    return {
      flipPosition: this.flipped ? -1 : 1
    }
  },
  created() {
    this.flipStartTimeout = null
    this.flipEndTimeout = null
  },
  watch: {
    flipped: function(newVal) {
      clearTimeout(this.flipStartTimeout)
      clearTimeout(this.flipEndTimeout)

      this.flipStartTimeout = setTimeout(() => {
        this.flipPosition = 0
        this.flipEndTimeout = setTimeout(() => {
          this.flipPosition = newVal === true ? -1 : 1
        }, 200)
      }, (this.position + 1) * Math.round(200 / Math.max(this.numberOfCards, 1)))
      // All cards will switch in 200ms.
    }
  },
  methods: {
    handleClick () {
      this.$emit('clicked', this.content);
    }
  }
}
</script>
<style lang="scss" scoped>
.flip-enter-active {
  transition: transform .2s;
  transition-timing-function: ease-out;
}

.flip-leave-active {
  transition: transform .2s;
  transition-timing-function: ease-in;
}

.flip-leave-to {
  transform: perspective(400px) rotateY(90deg);
}

.flip-enter /* .fade-leave-active below version 2.1.8 */ {
  transform: perspective(400px) rotateY(-90deg);
}
</style>