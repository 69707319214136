<template>
  <div
    :class="isCurrentUser ? 'text-black' : 'text-gray-500'"
    class="mr-0 pt-3 px-4 relative flex flex-row"
  >
    <font-awesome-icon
      v-if="user.role === 'dealer'"
      icon="crown"
      class="crown absolute mr-2"
    />
    <font-awesome-icon
      icon="user"
      class="text-lg mr-2"
    />
    <div class="flex-grow">
      {{ user.username }}
    </div>
    <div class="w-5">
      <font-awesome-icon
        v-if="hasPlayed"
        icon="check"
        class="text-xs text-primary"
      />
    </div>
    <button
      v-if="(currentUserIsDealer && user.role !== 'dealer') || isCurrentUser"
      @click.stop="toggleMenu"
      type="button"
      class="flex-grow-0 flex justify-center bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
      aria-haspopup="true"
      aria-expanded="true"
    >
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    </button>

    <div
      v-if="menuOpened"
      class="origin-top-right absolute right-0 mt-6 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
    >
      <div
        v-if="currentUserIsDealer && user.role !== 'dealer'"
        class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
      >
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          @click.stop.prevent="selectOption('role', 'dealer')"
        >
          Set as dealer
        </a>
      </div>
      <div
        v-if="isCurrentUser"
        class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
      >
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          @click.stop.prevent="selectOption('username', null)"
        >
          Change user name
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    isCurrentUser: {
      type: Boolean,
      required: true
    },
    currentUserIsDealer: {
      type: Boolean,
      required: true
    },
    hasPlayed: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menuOpened: false
    }
  },
  watch: {
    menuOpened (newVal) {
      if(newVal === true) {
        document.addEventListener("click", this.documentClick)
      } else {
        document.removeEventListener("click", this.documentClick)
      }
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
    },
    selectOption (name, value) {
      this.$emit('setOption', {
        user: this.user,
        name: name,
        value: value
      })
      this.menuOpened = false
    },
    documentClick () {
      this.menuOpened = false
    }
  }
}
</script>
<style lang="scss" scoped>
.crown{
  font-size: 9px;
  top: 2px;
  left: 18.5px;
}
</style>