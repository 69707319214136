<template>
  <div class="flex flex-col">
    <input
      type="text"
      class="bg-gray-100 rounded p-2 mb-0 text-gray-800 text-lg font-semibold outline-none"
      placeholder="Feature name"
      :disabled="this.user.role !== 'dealer'"
      :value="roundName"
      @keyup="setRoundName"
    >
    <div
      class="deck flex flex-wrap box-content border p-2 rounded bg-blue-100"
    >
      <Card
        v-for="(card, index) in cards"
        :key="index"
        :flipped="flipCard(card)"
        :showUser="roundIsClosed"
        :content="card"
        :position="index"
        :numberOfCards="cards.length"
      />

      <div
        v-if="showAverage"
        class=" mx-2 my-2 flex-shrink-0 border-gray-500 p-1 h-24 overflow-hidden flex flex-col items-center justify-center text-xl font-bold cursor-pointer"
      >
        <div class="text-gray-600 text-base">Average</div>
        <div class="text-2xl">{{ cardsAverage }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from './Card'

export default {
  components: { Card },
  props: {
    cards: {
      type: Array,
      required: true
    },
    roundId: {
      type: String,
      required: false, // because the component is created before receiving the room
      default: () => ''
    },
    roundName: {
      type: String,
      required: false, // because the component is created before receiving the room
      default: () => ''
    },
    roundIsClosed: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAverage: false
    }
  },
  watch: {
    roundIsClosed(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.showAverage = true
        }, 275)
      } else {
        this.showAverage = false
      }
    }
  },
  computed: {
    cardsWithFloatValues() {
      return this.cards.filter(c => Number.isFinite(c.value))
    },
    cardsAverage() {
      const total = this.cardsWithFloatValues.reduce((acc, card) => acc + parseFloat(card.value), 0)
      return Math.round(total / this.cardsWithFloatValues.length * 100) / 100
    }
  },
  methods: {
    flipCard(card) {
      if (this.roundIsClosed) {
        return false;
      } else {
        return card.user.browserId !== this.user.browserId
      }
    },
    setRoundName(event) {
      this.$emit('roundName', {
        roundId: this.roundId,
        name: event.target.value
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.deck {
  min-height: 10rem;
}
</style>