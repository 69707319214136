<template>
  <div class="home flex flex-col h-screen">
    <div class="flex-auto flex flex-col items-center justify-center">
      <img
        class="logo"
        src="images/pokerplan.svg"
      >
      <h1 class="text-2xl font-bold">
        Planning poker that just works
      </h1>
      <button
        class="mt-10 btn btn-blue px-16 py-2 text-xl font-normal"
        @click="start"
      >
        Create a room
      </button>
    </div>
    <div class="py-4">
      <a
        :href="contactEmail"
        class="hover:underline"
        title="Tell me what you think of Pokerplan"
      >
        Contact
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CryptoHelpers from '../helpers/Crypto'

export default {
  name: 'Home',
  components: {
  },
  computed: {
    contactEmail() {
      return `mailto:${atob('Y29udGFjdEBwb2tlcnBsYW4uY2g=')}`;
    }
  },
  methods: {
    start() {
      const token = CryptoHelpers.randomChars(10, true, false)
      this.$router.push({ name: 'room', params: { token } })
    }
  }
}
</script>
<style lang="scss" scoped>
.logo{
  margin-bottom: 20px;
}
</style>