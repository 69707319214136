import * as crypto from 'crypto';

export default class CryptoHelpers {
  static md5(str) {
    return crypto.createHash('md5').update(str).digest("hex");
  }

  static randomBytes(size) {
    return crypto.randomBytes(size);
  }

  static randomChars(size, lowerCase = false, specialChars = false) {
    const buffer = this.randomBytes(size);
    let str = buffer.toString('base64');

    if (specialChars) {
      str = str.replace('+', '!');
      str = str.replace('/', '@');
    } else {
      str = str.replace('+', '');
      str = str.replace('/', '');
    }
    str = str.replace('=', '');

    str = str.substr(0, size);

    /*
     * It is possible that the result is shorter than the requested size because
     * of the removal  characters we are doing above.
     * Tested between 0 and 3 times for 1 million stamps for size 16
     * We catch this here and go for another loop
     */
    if (str.length > size) {
      return this.randomChars(size, lowerCase, specialChars);
    }

    if (lowerCase) {
      str = str.toLowerCase();
    }

    return str;
  }
}

