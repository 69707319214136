<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
      class="relative inline-block text-left"
      :class="{disabled: disabled}"
    >
      <div>
        <button
          @click.stop="toggleOpen"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          :class="buttonClass"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {{ currentLabel }}
          <!-- Heroicon name: chevron-down -->
          <svg v-if="!disabled" class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      <div
        v-if="opened"
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      >
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a
            v-for="(option, index) in options"
            :key="index"
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click.stop.prevent="select(option)"
          >
            {{ option.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    buttonClass: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      opened: false,
      currentLabel: null
    }
  },
  watch: {
    opened (newVal) {
      if(newVal === true) {
        document.addEventListener("click", this.documentClick)
      } else {
        document.removeEventListener("click", this.documentClick)
      }
    },
    value (newVal) {
      const option = this.options.find(o => o.value === newVal);
      if (option) {
        this.select(option);
      }
    }
  },
  methods: {
    toggleOpen() {
      if (this.disabled) {
        return;
      }

      this.opened = !this.opened
    },
    select (option) {
      this.currentLabel = option.name
      this.$emit('select', option.value)
      this.opened = false
    },
    documentClick () {
      this.opened = false
    }
  }
}
</script>